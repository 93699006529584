.ag-theme-balham .ag-header-cell::after, .ag-theme-balham .ag-header-group-cell::after {
  border-right: none !important;
  content: " ";
  height: 16px;
  margin-top: 8px;
  position: absolute;
  right: 0;
  text-indent: -2000px;
  top: 0;
}
/* body {
  margin: 0;
  background-color: #fafafa;
  height: 100%;
  overflow: hidden;
}
.ag-header-cell {
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  position: absolute;
  vertical-align: bottom;
  border: 1px solid #ddd;
  border-collapse: separate;
  padding: 0px 10px;
}
.ag-cell {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-collapse: separate;
  border: 1px solid #ddd;
  padding:0px 10px;
 
} */
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
  line-height: 32px;
  padding-left: 77px !important;
}

.ag-theme-balham .ag-root {
  border: none !important;
}

.ag-theme-balham {
  
  color: #000;
  font: 400 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.ag-body-container
{
  width:100% !important;
  margin-left: 0px 50px !important;
}

.ag-theme-balham .ag-cell {
  line-height: 26px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid transparent;
  padding-left: 11px;
  padding-right: 11px;
}

.element.style {
  width: 100px !important;
  left: 200px;
}

/* .ag-body-viewport.ag-layout-normal {
  overflow-x: auto;
  overflow-y: auto;
  height: 900px !important;
} */