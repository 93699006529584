.ag-theme-balham .ag-header-cell::after, .ag-theme-balham .ag-header-group-cell::after {
  
  content: " ";
  height: 16px;
  margin-top: 8px;
  position: absolute;
  right: 0;
  text-indent: -2000px;
  top: 0;
}
.ag-theme-material .ag-cell {
  border:0.5pt solid #f0f2f5 !important;
  padding-left: 10px !important;
}
.ag-header-cell {
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  position: absolute;
  vertical-align: bottom;
  border: 1px solid #ddd;
  border-collapse: separate;
  background: #f0f2f5;
  padding: 0px 10px;
  /* text-align: left !important; */
  padding-left: 10px !important;
  padding-right: 5px !important;
}

.ag-theme-material .ag-row {
  border-color: white !important;
  box-sizing: border-box;
  border-width: 0;
}

.ag-row {
  box-sizing: border-box !important;
  width: 0% !important;
}

body {
  margin: 0;
  background-color: #fafafa;
  height: 100%;
  overflow-x: hidden !important;
}

.ag-cell {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ddd !important;
  /* text-align: left !important; */
 
}
.ag-theme-balham .ag-cell {
  line-height: 26px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #ddd !important;
  padding-left: 11px;
  padding-right: 11px;
  text-align: left;
}
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
  line-height: 32px;
  padding-left: 77px !important;
}

.ag-theme-balham .ag-root {
  border: none !important;
}

.ag-theme-balham {
  
  color: #000;
  font: 400 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.ag-body-container
{
  width:100% !important;
  margin-left: 0px 50px !important;
}

.element.style {
  width: 100px !important;
  left: 200px;
}

.ag-body {
  box-sizing: border-box;
  position: relative;
  display: flex;
  height: 40px !important;
}

.chartbox {
  padding: 30px;
  width: 100%;
}

.chartA {
  margin-right: 20px;
  margin-top: 20px;
}

.chartB {
  margin-left: 20px;
}
