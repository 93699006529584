body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.ag-theme-balham .ag-header-cell::after, .ag-theme-balham .ag-header-group-cell::after {
  border-right: none !important;
  content: " ";
  height: 16px;
  margin-top: 8px;
  position: absolute;
  right: 0;
  text-indent: -2000px;
  top: 0;
}
/* body {
  margin: 0;
  background-color: #fafafa;
  height: 100%;
  overflow: hidden;
}
.ag-header-cell {
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  position: absolute;
  vertical-align: bottom;
  border: 1px solid #ddd;
  border-collapse: separate;
  padding: 0px 10px;
}
.ag-cell {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-collapse: separate;
  border: 1px solid #ddd;
  padding:0px 10px;
 
} */
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
  line-height: 32px;
  padding-left: 77px !important;
}

.ag-theme-balham .ag-root {
  border: none !important;
}

.ag-theme-balham {
  
  color: #000;
  font: 400 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.ag-body-container
{
  width:100% !important;
  margin-left: 0px 50px !important;
}

.ag-theme-balham .ag-cell {
  line-height: 26px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid transparent;
  padding-left: 11px;
  padding-right: 11px;
}

.element.style {
  width: 100px !important;
  left: 200px;
}

/* .ag-body-viewport.ag-layout-normal {
  overflow-x: auto;
  overflow-y: auto;
  height: 900px !important;
} */
.ag-theme-balham .ag-header-cell::after, .ag-theme-balham .ag-header-group-cell::after {
  
  content: " ";
  height: 16px;
  margin-top: 8px;
  position: absolute;
  right: 0;
  text-indent: -2000px;
  top: 0;
}
.ag-theme-material .ag-cell {
  border:0.5pt solid #f0f2f5 !important;
  padding-left: 10px !important;
}
.ag-header-cell {
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  position: absolute;
  vertical-align: bottom;
  border: 1px solid #ddd;
  border-collapse: separate;
  background: #f0f2f5;
  padding: 0px 10px;
  /* text-align: left !important; */
  padding-left: 10px !important;
  padding-right: 5px !important;
}

.ag-theme-material .ag-row {
  border-color: white !important;
  box-sizing: border-box;
  border-width: 0;
}

.ag-row {
  box-sizing: border-box !important;
  width: 0% !important;
}

body {
  margin: 0;
  background-color: #fafafa;
  height: 100%;
  overflow-x: hidden !important;
}

.ag-cell {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ddd !important;
  /* text-align: left !important; */
 
}
.ag-theme-balham .ag-cell {
  line-height: 26px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #ddd !important;
  padding-left: 11px;
  padding-right: 11px;
  text-align: left;
}
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
  line-height: 32px;
  padding-left: 77px !important;
}

.ag-theme-balham .ag-root {
  border: none !important;
}

.ag-theme-balham {
  
  color: #000;
  font: 400 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.ag-body-container
{
  width:100% !important;
  margin-left: 0px 50px !important;
}

.element.style {
  width: 100px !important;
  left: 200px;
}

.ag-body {
  box-sizing: border-box;
  position: relative;
  display: flex;
  height: 40px !important;
}

.chartbox {
  padding: 30px;
  width: 100%;
}

.chartA {
  margin-right: 20px;
  margin-top: 20px;
}

.chartB {
  margin-left: 20px;
}

